.active {
  color: #1EB6C3 !important;
}
.ol-class {
  list-style: none;
  counter-reset: my-awesome-counter;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.ol-class .li-class {
  counter-increment: my-awesome-counter;
  margin-bottom: 0.5rem;
}
.ol-class .li-class::before {
  content: "0" counter(my-awesome-counter);
  font-weight: bold;
  font-size: 3rem;
  margin-right: 0.5rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1;
}
